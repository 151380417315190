/* Navbar Styles */
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
.header {
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
height: 80px;
position: fixed;
top: 0;
background-color: rgb(243, 243, 243);
z-index: 999;
transition: background-color 0.5s ease-in-out;
}

.header-bg {
background-color: #ffffff;
color: white;
}

.header h2 {
margin-left: 20px;
font-size: 1.76rem;
}

.nav-menu {
display: flex;
flex-direction: row;
justify-content: flex-end;
list-style: none;
margin-right: 20px;
margin-top: 0;
margin-bottom: 0;
padding-left: 0;
}

.nav-menu li {
display: inline-block;
margin: 0 10px;
position: relative;
cursor:pointer;
}

.nav-menu li::after {
content: '';
position: absolute;
bottom: -3px;
left: 0;
width: 0%;
height: 2px;
background-color: #535353;
transition: width 0.3s ease-in-out;
}

.nav-menu li:hover::after {
width: 100%;
}

.nav-menu p {
font-size: 1.16rem;
text-decoration: none;
color: black;
transition: color 0.3s ease-in-out;
}

.nav-menu p:hover {
color: #262626;
}

.hamburger {
display: none;
margin-right: 20px;
cursor: pointer;
}

.lang_flag {
cursor: pointer;
}

.lang_flag img {
height: 30px;
}

@media screen and (max-width: 768px) {
    .header {
        flex-direction: row;
        justify-content: space-between;
        padding: 20px;
        }

.nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: fixed;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    width: 100%;
    background-color: white;
    z-index: 999;
}

.nav-menu.active {
    opacity: 1;
    left: 0;
}
.nav-menu p {
  font-size: 1.26rem;
  text-decoration: none;
  color: black;
  transition: color 0.3s ease-in-out;
  }
.nav-menu li {
    margin: 10px 0;
}

.hamburger {
display: inline-block;
}

.hamburger:hover {
    color: #262626;
}
}


.hero {
display: flex;
justify-content: flex-start;
align-items: center;
height: 100vh;
background-image: url("background2.png");
background-size:cover;
background-repeat: no-repeat;
color: white;
text-align: left;
width: 100%;
overflow:hidden;
animation: fadeInAnimation ease 6s;
animation-iteration-count: 1;
animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
0% {
    opacity: 0;
}
100% {
    opacity: 1;
}
}

.content {
padding-top: 28rem;
height: 100%;
width:60%;
}

.content span {
font-size: 1.36rem;
font-weight: bold;
line-height: 1.7;
color:rgb(255, 215, 155);
margin-left: 2rem;
}


.services {
border-top: solid 2px;
background-color: rgb(0, 0, 0);
display: flex;
flex-direction: row;
justify-content: space-between;
width:100%;
color:rgb(255, 215, 155);
height:100vh;
border-bottom: solid 2px;
}

.service_header{
display:flex;
flex-grow: 1;
flex-direction: column;
margin:0;
padding:0;
background-color: #000000;
border-right: 1px solid #ffffff;
width:20%;
transform: translateX(0);
transition: all 0.5s ease-in-out;
}
.service_header p{
color:rgb(255, 215, 155);
font-size: 1.36rem;
padding-left:2rem;
padding-top:18rem;
line-height: 1.7;
}

.service_selector {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color:rgb(255, 215, 155);
  flex-grow: 0.5;
  background-color: #000000;
  transform: translateX(0);
  transition: all 0.5s ease-in-out;
}

.service_selector ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  padding-top:17rem;
}

.service_selector li {
  font-size: 1.36rem;
  padding: 1rem;
  cursor: pointer;
}

.service_selector li.selected {
        border-bottom: 3px solid #ffffff;
}
      
.service_desc {
margin: 0;
flex-grow: 8;
width: 10%;
background-image: url("serv_desc_bg.png");
background-size:cover;
background-repeat: no-repeat;
word-wrap: break-word;
padding-right: 1rem;
transform: translateX(0);
transition: all 0.5s ease-in-out;
}
      
.service_desc p{
    font-size: 1.36rem;
    padding-top:20rem;
    color:rgb(255, 215, 155);
    line-height: 1.7;
    text-align: start;
}

.fade-in-out {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-out, visibility 1s ease-out;
}

.fade-in-out.show {
  opacity: 1;
  visibility: visible;
}

.tech_stack{
  border-top: solid 2px;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: row;
  width:100%;
  color:rgb(255, 215, 155);
  height:100vh;
  flex-wrap: wrap;
  padding-top:2rem;
  padding-left: 2rem;
  margin-bottom: 6rem;
}

.tech_stack div {
  flex-basis: calc(31.7%);
  justify-content: center;
  align-items:center ;
  margin-right: 1rem;
  margin-bottom: 2rem;
}
.tech_stack div:hover {
transform: scale(1.01);
}

.backend {
  background-image: url("python.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 33.333%;
  text-align: center;
  
}

.backend h2 {
  font-size: 1.76rem;
  margin-bottom: 7rem;
}

.backend p {
  font-size: 1.36rem;
  margin-bottom: 1.5rem;
  color:rgb(255, 215, 155);
}

.frontend {
  background-image: url("frontend.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 33.333%;
  text-align: center;
}

.frontend h2 {
  font-size: 1.76rem;
  margin-bottom: 7rem;
}

.frontend p {
  font-size: 1.36rem;
  margin-bottom: 1.5rem;
  color:rgb(255, 215, 155);
}

.cloud {
  background-image: url("cloud.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 33.333%;
  text-align: center;
  
}

.cloud h2 {
  font-size: 1.76rem;
  margin-bottom: 7rem;
}

.cloud p {
  font-size: 1.36rem;
  margin-bottom: 1.5rem;
  color:rgb(255, 215, 155);
}

.databases {
  background-image: url("databases.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 33.333%;
  text-align: center;
  
}

.databases h2 {
  font-size: 1.76rem;
  margin-bottom: 7rem;
}

.databases p {
  font-size: 1.36rem;
  margin-bottom: 1.5rem;
  color:rgb(255, 215, 155);
}

.visual {
  background-image: url("visual.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 33.333%;
  text-align: center;
  
}

.visual h2 {
  font-size: 1.76rem;
  margin-bottom: 7rem;
}

.visual p {
  font-size: 1.36rem;
  margin-bottom: 1.5rem;
  color:rgb(255, 215, 155);
}
.airflow {
  background-image: url("airflow.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 33.333%;
  text-align: center;
}

.airflow h2 {
  font-size: 1.76rem;
  margin-bottom: 7rem;
}

.airflow p {
  font-size: 1.36rem;
  margin-bottom: 1.5rem;
  color:rgb(255, 215, 155);
}
.devops {
  background-image: url("devops.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 33.333%;
  text-align: center;
}

.devops h2 {
  font-size: 1.76rem;
  margin-bottom: 7rem;
}

.devops p {
  font-size: 1.36rem;
  margin-bottom: 1.5rem;
  color:rgb(255, 215, 155);
}

.mining {
  background-image: url("mining.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 33.333%;
  text-align: center;
}

.mining h2 {
  font-size: 1.76rem;
  margin-bottom: 7rem;
}

.mining p {
  font-size: 1.36rem;
  margin-bottom: 1.5rem;
  color:rgb(255, 215, 155);
}

.setup {
  background-image: url("setup.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 33.333%;
  text-align: center;
}

.setup h2 {
  font-size: 1.76rem;
  margin-bottom: 7rem;
}

.setup p {
  font-size: 1.36rem;
  margin-bottom: 1.5rem;
  color:rgb(255, 215, 155);
}


.customers {
  border-top: solid 2px;
  background-color: rgb(0, 0, 0);
  background-image: url("partners_bg.png");
  background-size:cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  width: 100%;
  color:rgb(255, 215, 155);
  justify-content: space-evenly;
  height: 100vh;
  
}

.customers ul {
  display: flex;/* added to vertically center items */
  align-self: center;
  flex-direction: row;
  gap: 15rem;
}
.customers p{
  width: 100%;
  color:rgb(255, 215, 155);
  font-size: 1.36rem;
}

.team{
  border-top: solid 2px;
  background-color: rgb(0, 0, 0);
  background-image: url("background2.png");
  background-size:cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  width: 100%;
  color:rgb(255, 215, 155);
  justify-content: space-evenly;
  height: 100vh;
}
.team ul {
  display: flex;/* added to vertically center items */
  align-self: center;
  flex-direction: row;
  gap: 15rem;
}
.team p{
  width: 100%;
  color:rgb(255, 215, 155);
  font-size: 1.36rem;
}

.external{
  background-image: url("background2.png");
  background-size:cover;
  background-repeat: no-repeat;
  display: grid;
  place-items: center;
  width: 100%;
}
.container {  display: grid;
  grid-template-columns: 1fr 0.5fr 1.5fr;
  grid-template-rows: 1fr 1.9fr 0.1fr;
  gap: 0px 0px;
  width: 100%;
  box-shadow: 0 0 15px rgb(51, 50, 50);
  background-color: rgb(0, 0, 0);
  padding-top: 2rem;
  border-top: 2px solid rgb(255, 215, 155);
  grid-auto-flow: row;
  grid-template-areas:
    "left_container left_container right_container"
    "left_container left_container right_container"
    "copyright copyright copyright";
}

.left_container { 
grid-area: left_container; 
background-color: rgb(0, 0, 0);
text-align: center;
border-bottom: 2px solid rgb(255, 215, 155) ;
padding-bottom:1rem;
}
.left_container li{ 
padding-top: 0.5rem;
}
.container p {
  color:rgb(255, 215, 155);
  font-size:1.1rem;
}
.container h2{
  color:rgb(255, 215, 155);
}

.left_container img{ 
  width: 3rem;
  height:100%; }

.right_container {
   grid-area: right_container;
   background-color: rgb(0, 0, 0);
   text-align: center; 
   border-bottom: 2px solid rgb(255, 215, 155);
   padding-bottom:1rem;
   padding-right:1rem;}

.copyright { 
  padding-top: 1rem;
  grid-area: copyright;
  
 }
 .copyright p{
 color:rgb(255, 215, 155);
 text-align: center;
 padding-bottom:1rem;
 }
    @media screen and (max-width:1600px) {

      .content {
        padding-top: 25rem;
        height: 100vh;
        width:100%;
    }
      
      .content span {
        font-size: 1.16rem;
        font-weight: bold;
        line-height: 1.4;
        color:rgb(255, 215, 155);
        margin-left: 1rem;
      }
      
      .services {
        background-color: rgb(0, 0, 0);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width:100%;
        color:rgb(255, 215, 155);
        height:100vh;
      }
      
      .service_header{
        display:flex;
        flex-grow: 2.7;
        flex-direction: column;
        margin:0;
        padding:0;
        background-color: #000000;
        border-right: 1px solid #ffffff;
        width:20%;
        transform: translateX(0);
        transition: all 0.5s ease-in-out;
        padding-top: 0rem;
      }
      .service_header p{
      color:rgb(255, 215, 155);
      font-size: 1.16rem;
      padding-left:1.5rem;
      line-height: 1.5;
      }
    
      .service_selector {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 20px;
        color:rgb(255, 215, 155);
        flex-grow: 0.3;
        background-color: #000000;
        transform: translateX(0);
        transition: all 0.5s ease-in-out;
      }
      
      .service_selector ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        padding-top:16.7rem;
      }
      
      .service_selector li {
        font-size: 1.16rem;
        padding: 0.7rem;
        cursor: pointer;
      }
    
    .service_selector li.selected {
            border-bottom: 3px solid #ffffff;
          }
          
    .service_desc {
    margin: 0;
    flex-grow: 9;
    width: 10%;
    background-image: url("serv_desc_bg.png");
    background-size:cover;
    background-repeat: no-repeat;
    word-wrap: break-word;
    padding-right: 1rem;
    transform: translateX(0);
    transition: all 0.5s ease-in-out;
    }
          
    .service_desc p{
        font-size: 1.16rem;
        padding-top:18rem;
        color:rgb(255, 215, 155);
        line-height: 1.7;
        text-align: start;
    }
    
    .tech_stack{
      border-top: solid 2px;
      background-color: rgb(0, 0, 0);
      display: flex;
      flex-direction: row;
      width:100%;
      color:rgb(255, 215, 155);
      height:100vh;
      flex-wrap: wrap;
      padding-top:2rem;
      padding-left: 2rem;
    }
    
    .tech_stack div {
      flex-basis: calc(31%);
      justify-content: center;
      align-items:center ;
      margin-right: 2rem;
      margin-bottom: 2rem;
    }
    
    .backend {
      background-image: url("python.png");
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 33.3333%;
      text-align: center;
      
    }
    
    .backend h2 {
      font-size: 1.66rem;
      margin-bottom: 4rem;
    }
    
    .backend p {
      font-size: 1.16rem;
      margin-bottom: 1.5rem;
      color:rgb(255, 215, 155);
    }
    
    .frontend {
      background-image: url("frontend.png");
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 33.3333%;
      text-align: center;
    }
    
    .frontend h2 {
      font-size: 1.66rem;
      margin-bottom: 4rem;
    }
    
    .frontend p {
      font-size: 1.16rem;
      margin-bottom: 1.5rem;
      color:rgb(255, 215, 155);
    }
    
    .cloud {
      background-image: url("cloud.png");
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 33.3333%;
      text-align: center;
      
    }
    
    .cloud h2 {
      font-size: 1.66rem;
      margin-bottom: 4rem;
    }
    
    .cloud p {
      font-size: 1.16rem;
      margin-bottom: 1.5rem;
      color:rgb(255, 215, 155);
    }
    
    .databases {
      background-image: url("databases.png");
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 33.3333%;
      text-align: center;
      
    }
    
    .databases h2 {
      font-size: 1.66rem;
      margin-bottom: 4rem;
    }
    
    .databases p {
      font-size: 1.16rem;
      margin-bottom: 1.5rem;
      color:rgb(255, 215, 155);
    }
    
    .visual {
      background-image: url("visual.png");
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 33.3333%;
      text-align: center;
      
    }
    
    .visual h2 {
      font-size: 1.66rem;
      margin-bottom: 4rem;
    }
    
    .visual p {
      font-size: 1.16rem;
      margin-bottom: 1.5rem;
      color:rgb(255, 215, 155);
    }
    
    .airflow {
      background-image: url("airflow.png");
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 33%;
      text-align: center;
    }
    
    .airflow h2 {
      font-size: 1.66rem;
      margin-bottom: 4rem;
    }
    
    .airflow p {
      font-size: 1.16rem;
      margin-bottom: 1.5rem;
      color:rgb(255, 215, 155);
    }
    .devops {
      background-image: url("devops.png");
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 33%;
      text-align: center;
    }
    
    .devops h2 {
      font-size: 1.66rem;
      margin-bottom: 4rem;
    }
    
    .devops p {
      font-size: 1.16rem;
      margin-bottom: 1.5rem;
      color:rgb(255, 215, 155);
    }
    
    .mining {
      background-image: url("mining.png");
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 33%;
      text-align: center;
    }
    
    .mining h2 {
      font-size: 1.66rem;
      margin-bottom: 4rem;
    }
    
    .mining p {
      font-size: 1.16rem;
      margin-bottom: 1.5rem;
      color:rgb(255, 215, 155);
    }
    
    .setup {
      background-image: url("setup.png");
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 33%;
      text-align: center;
    }
    
    .setup h2 {
      font-size: 1.66rem;
      margin-bottom: 4rem;
    }
    
    .setup p {
      font-size: 1.16rem;
      margin-bottom: 1.5rem;
      color:rgb(255, 215, 155);
    }

    .customers {
      border-top: solid 2px;
      background-color: rgb(0, 0, 0);
      background-image: url("partners_bg.png");
      background-size:cover;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: row;
      width: 100%;
      color:rgb(255, 215, 155);
      justify-content: space-evenly;
      height: 100vh;
      padding-left: 1rem;
    }
    
    .customers ul {
      display: flex;/* added to vertically center items */
      align-self: center;
      flex-direction: row;
      gap: 10rem;
      height: 50%;
    }
    .customers p{
      width: 100%;
      color:rgb(255, 215, 155);
      font-size: 1.1rem;
    }
    .team{
      border-top: solid 2px;
      background-color: rgb(0, 0, 0);
      background-image: url("background2.png");
      background-size:cover;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: row;
      width: 100%;
      color:rgb(255, 215, 155);
      justify-content: space-evenly;
      height: 100vh;
      padding-left: 1rem;
    }
    .team ul {
      display: flex;/* added to vertically center items */
      align-self: center;
      flex-direction: row;
      gap: 10rem;
      height: 50%;
    }
    .team p{
      width: 100%;
      color:rgb(255, 215, 155);
      font-size: 1.1rem;
    }
          }
          @media screen and (max-width:1400px) {

            .content {
              padding-top: 25rem;
              height: 100%;
              width:100%;
          }
            
            .content span {
              font-size: 1.1rem;
              font-weight: bold;
              line-height: 1.4;
              color:rgb(255, 215, 155);
              margin-left: 1rem;
            }
            
            .services {
              background-color: rgb(0, 0, 0);
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width:100%;
              color:rgb(255, 215, 155);
              height:100vh;
          }
          
          .service_header{
              display:flex;
              flex-direction: row;
              margin:0;
              padding:0;
              background-color: #000000;
              border-right: none;
              border-bottom: 1px solid #ffffff;
              width:100%;
              transform: translateX(0);
              transition: all 0.5s ease-in-out;
              padding-bottom: 0.4rem;
              justify-content: center;
          }
          
          .service_header p{
          color:rgb(255, 215, 155);
          font-size: 1.06rem;
          padding-left:0rem;
          padding-top:0.6rem;
          line-height: 1.5;
          text-align: center;
          }
          
          .service_selector {
              justify-content: center;
              display: flex;
              flex-direction: row;
              padding-bottom: 0.4rem;
              color:rgb(255, 215, 155);
              background-color: #000000;
              transform: translateX(0);
              transition: all 0.5s ease-in-out;
              width: 100%;
              height: 40vh;
          }
          
          .service_selector ul {
              list-style: none;
              margin: 0;
              padding: 0;
              display: flex;
              flex-direction: row;
              padding-top:3rem;
              flex-wrap: wrap;
          }
          
          .service_selector li {
              flex: 0 0 33.33333%;
              font-size: 1.06rem;
              cursor: pointer;
              border: 2px solid;
              text-align: center;
          }
          
          .service_selector li.selected {
                  background-color: rgb(255, 215, 155);
                  color:#000000;
                  border: 2px solid black;
              }
              
          .service_desc {
          margin: 0;
          width: 100%;
          background-image: url("serv_desc_bg.png");
          background-size:cover;
          background-repeat: no-repeat;
          word-wrap: break-word;
          padding-right: 0.2rem;
          padding-left: 0.2rem;
          transform: translateX(0);
          transition: all 0.5s ease-in-out;
          justify-content: center;
          padding-top:0rem;
          height: 100vh;
          }
              
          .service_desc p{
              font-size: 1.06rem;
              padding-top:0rem;
              color:rgb(255, 215, 155);
              line-height: 1.5;
              text-align: center;
              padding-bottom: 0.4rem;
          }
          
          .tech_stack{
            border-top: solid 2px;
            background-color: rgb(0, 0, 0);
            display: flex;
            flex-direction: row;
            width:100%;
            color:rgb(255, 215, 155);
            height:100vh;
            padding-top:2rem;
            padding-left: 12rem;
          }
          
          .tech_stack div {
            flex-basis: calc(25%);
            justify-content: center;
            align-items:center ;
            margin-bottom: 2rem;
          }
          
          .backend {
            background-image: url("python.png");
            background-size: fill;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 33.3333%;
            text-align: center;
            
          }
          
          .backend h2 {
            font-size: 1.46rem;
            margin-bottom: 4rem;
          }
          
          .backend p {
            font-size: 1.1rem;
            margin-bottom: 1.5rem;
            color:rgb(255, 215, 155);
          }
          
          .frontend {
            background-image: url("frontend.png");
            background-size: fill;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 33.3333%;
            text-align: center;
          }
          
          .frontend h2 {
            font-size: 1.46rem;
            margin-bottom: 4rem;
          }
          
          .frontend p {
            font-size: 1.1rem;
            margin-bottom: 1.5rem;
            color:rgb(255, 215, 155);
          }
          
          .cloud {
            background-image: url("cloud.png");
            background-size: fill;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 33.3333%;
            text-align: center;
            
          }
          
          .cloud h2 {
            font-size: 1.46rem;
            margin-bottom: 4rem;
          }
          
          .cloud p {
            font-size: 1.1rem;
            margin-bottom: 1.5rem;
            color:rgb(255, 215, 155);
          }
          
          .databases {
            background-image: url("databases.png");
            background-size: fill;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 33.3333%;
            text-align: center;
            
          }
          
          .databases h2 {
            font-size: 1.46rem;
            margin-bottom: 4rem;
          }
          
          .databases p {
            font-size: 1.1rem;
            margin-bottom: 1.5rem;
            color:rgb(255, 215, 155);
          }
          
          .visual {
            background-image: url("visual.png");
            background-size: fill;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 33.3333%;
            text-align: center;
            
          }
          
          .visual h2 {
            font-size: 1.46rem;
            margin-bottom: 4rem;
          }
          
          .visual p {
            font-size: 1.1rem;
            margin-bottom: 1.5rem;
            color:rgb(255, 215, 155);
          }
          
          .airflow {
            background-image: url("airflow.png");
            background-size: fill;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 33%;
            text-align: center;
          }
          
          .airflow h2 {
            font-size: 1.46rem;
            margin-bottom: 4rem;
          }
          
          .airflow p {
            font-size: 1.1rem;
            margin-bottom: 1.5rem;
            color:rgb(255, 215, 155);
          }
          .devops {
            background-image: url("devops.png");
            background-size: fill;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 33%;
            text-align: center;
          }
          
          .devops h2 {
            font-size: 1.46rem;
            margin-bottom: 4rem;
          }
          
          .devops p {
            font-size: 1.1rem;
            margin-bottom: 1.5rem;
            color:rgb(255, 215, 155);
          }
          
          .mining {
            background-image: url("mining.png");
            background-size: fill;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 33%;
            text-align: center;
          }
          
          .mining h2 {
            font-size: 1.46rem;
            margin-bottom: 4rem;
          }
          
          .mining p {
            font-size: 1.1rem;
            margin-bottom: 1.5rem;
            color:rgb(255, 215, 155);
          }
          
          .setup {
            background-image: url("setup.png");
            background-size: fill;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 33%;
            text-align: center;
          }
          
          .setup h2 {
            font-size: 1.46rem;
            margin-bottom: 4rem;
          }
          
          .setup p {
            font-size: 1.1rem;
            margin-bottom: 1.5rem;
            color:rgb(255, 215, 155);
          }
          .customers {
            border-top: solid 2px;
            background-color: rgb(0, 0, 0);
            background-image: url("partners_bg.png");
            background-size:cover;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            color:rgb(255, 215, 155);
            justify-content: space-evenly;
            padding-left: 1rem;
            height: 100vh;
          }
          
          .customers ul {
            display: flex;/* added to vertically center items */
            align-self: center;
            flex-direction: row;
            gap: 1rem;
            height: 50%;
          }
          .customers p{
            width: 100%;
            color:rgb(255, 215, 155);
            font-size: 1rem;
          }
          .team {
            border-top: solid 2px;
            background-color: rgb(0, 0, 0);
            background-image: url("background2.png");
            background-size:cover;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            color:rgb(255, 215, 155);
            justify-content: space-evenly;
            padding-left: 1rem;
            height: 100vh;
          }
          
          .team ul {
            display: flex;/* added to vertically center items */
            align-self: center;
            flex-direction: row;
            gap: 1rem;
            height: 50%;
          }
          .team p{
            width: 100%;
            color:rgb(255, 215, 155);
            font-size: 1rem;
          }
          .container {  display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 0.1fr;
            gap: 0px 0px;
            height: 100%;
            width: 100%;
            box-shadow: 0 0 15px rgb(51, 50, 50);
            padding-top:2rem;
            border-top: 2px solid rgb(255, 215, 155);;
            grid-auto-flow: row;
            grid-template-areas:
            "left_container"
            "right_container"
            "copyright";
          }
          
          .left_container { 
          grid-area: left_container; 
          background-color: rgb(0, 0, 0);
          text-align: center;
          border-bottom: 2px solid rgb(255, 215, 155) ;
          padding-bottom:1rem;
          padding-top: 4rem;
          }
          .left_container li{ 
          padding-top: 0.3rem;
          }
          .container p {
            color:rgb(255, 215, 155);
            font-size:1rem;
          }
          .container h2{
            color:rgb(255, 215, 155);
            font-size: 1.1rem;
          }
          
          .left_container img{ 
            width: 3rem;
            height:100%; }
          
          .right_container {
             grid-area: right_container;
             background-color: rgb(0, 0, 0);
             text-align: center; 
             border-bottom: 2px solid rgb(255, 215, 155);
             padding-bottom:1rem;
             padding-right:0rem;
             padding-top: 2rem;}
          
          .copyright { 
            padding-top: 1rem;
            grid-area: copyright;
            
           }
           .copyright p{
           color:rgb(255, 215, 155);
           text-align: center;
           padding-bottom:1rem;
           }
                }
@media screen and (max-width:760px) {

.hero{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.content {
    height: 50%;
    width:100%;
    display: flex;
    justify-content: center;
    padding-top: 8rem;
}

.content span {
    font-size: 0.95rem;
    font-weight: bold;
    line-height: 0.7;
    color:rgb(255, 215, 155);
    margin-left:0rem;
}

.content p{
    text-align: center;
}

.services {
    background-color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width:100%;
    color:rgb(255, 215, 155);
    height:100vh;
}

.service_header{
    display:flex;
    flex-direction: row;
    margin:0;
    padding:0;
    background-color: #000000;
    border-right: none;
    border-bottom: 1px solid #ffffff;
    width:100%;
    transform: translateX(0);
    transition: all 0.5s ease-in-out;
    padding-bottom: 0.4rem;
    justify-content: center;
}

.service_header p{
color:rgb(255, 215, 155);
font-size: 0.85rem;
padding-top:0.6rem;
line-height: 1.3;
text-align: center;
width: 100%;
}

.service_selector {
    display: flex;
    flex-direction: row;
    padding-bottom: 0.4rem;
    color:rgb(255, 215, 155);
    background-color: #000000;
    transform: translateX(0);
    transition: all 0.5s ease-in-out;
    width: 100%;
    height: 40vh;
}

.service_selector ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    padding-top:3rem;
    flex-wrap: wrap;
}

.service_selector li {
    flex: 0 0 33%;
    font-size: 0.80rem;
    cursor: pointer;
    border: 2px solid;
    text-align: center;
}

.service_selector li.selected {
        background-color: rgb(255, 215, 155);
        color:#000000;
        border: 2px solid black;
    }
    
.service_desc {
margin: 0;
width: 100%;
background-image: url("serv_desc_bg.png");
background-size:cover;
background-repeat: no-repeat;
word-wrap: break-word;
padding-right: 0.2rem;
padding-left: 0.2rem;
transform: translateX(0);
transition: all 0.5s ease-in-out;
justify-content: center;
padding-top:0rem;
height: 100vh;
}
    
.service_desc p{
    font-size: 0.80rem;
    padding-top:0rem;
    color:rgb(255, 215, 155);
    line-height: 1.5;
    text-align: center;
    padding-bottom: 0.4rem;
}
.tech_stack{
  border-top: solid 2px;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: row;
  width:100%;
  color:rgb(255, 215, 155);
  height:auto;
  flex-wrap: wrap;
  padding-top:1rem;
  padding-left: 0rem;
  padding-right: 0rem;
  margin-bottom: 2rem;
}

.tech_stack div {
  flex: 0 0 100%;
  justify-content: center;
  align-items:center ;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.backend {
  background-image: url("python.png");
  background-size: fill;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 28%;
  text-align: center;
  
}

.backend h2 {
  font-size: 1.7rem;
  margin-bottom: 4rem;
}

.backend p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color:rgb(255, 215, 155);
}

.frontend {
  background-image: url("frontend.png");
  background-size: fill;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 29%;
  text-align: center;
}

.frontend h2 {
  font-size: 1.7rem;
  margin-bottom: 4rem;
}

.frontend p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color:rgb(255, 215, 155);
}

.cloud {
  background-image: url("cloud.png");
  background-size: fill;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 26.7%;
  text-align: center;
}

.cloud h2 {
  font-size: 1.7rem;
  margin-bottom: 4rem;
}

.cloud p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color:rgb(255, 215, 155);
}

.databases {
  background-image: url("databases.png");
  background-size: fill;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 29.7%;
  text-align: center;
  
}

.databases h2 {
  font-size: 1.7rem;
  margin-bottom: 4rem;
}

.databases p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color:rgb(255, 215, 155);
}

.visual {
  background-image: url("visual.png");
  background-size: fill;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 28%;
  text-align: center;
  
}

.visual h2 {
  font-size: 1.7rem;
  margin-bottom: 4rem;
}

.visual p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color:rgb(255, 215, 155);
}

.airflow {
  background-image: url("airflow.png");
  background-size: fill;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 28%;
  text-align: center;
}

.airflow h2 {
  font-size: 1.7rem;
  margin-bottom: 4rem;
}

.airflow p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color:rgb(255, 215, 155);
}
.devops {
  background-image: url("devops.png");
  background-size: fill;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 28%;
  text-align: center;
}

.devops h2 {
  font-size: 1.7rem;
  margin-bottom: 4rem;
}

.devops p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color:rgb(255, 215, 155);
}

.mining {
  background-image: url("mining.png");
  background-size: fill;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 28%;
  text-align: center;
}

.mining h2 {
  font-size: 1.7rem;
  margin-bottom: 4rem;
}

.mining p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color:rgb(255, 215, 155);
}

.setup {
  background-image: url("setup.png");
  background-size: fill;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 27%;
  text-align: center;
}

.setup h2 {
  font-size: 1.7rem;
  margin-bottom: 4rem;
}

.setup p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color:rgb(255, 215, 155);
}

.customers {
  border-top: solid 2px;
  background-color: rgb(0, 0, 0);
  background-image: url("partners_bg.png");
  background-size:cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  color:rgb(255, 215, 155);
  justify-content: space-evenly;
  padding-left: 0rem;
  height: 100%;
}

.customers ul {
  padding-top:2rem;
  padding-bottom:2rem;
  display: flex;
  align-self: center;
  flex-direction: row;
  gap: 4rem;
  height: 100%;
  width: 80%;
  flex-wrap: wrap;
  justify-content: center;
}
.customers p{
  width: 100%;
  color:rgb(255, 215, 155);
  font-size: 0.8rem;
}
.team {
  border-top: solid 2px;
  background-color: rgb(0, 0, 0);
  background-image: url("background2.png");
  background-size:cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  color:rgb(255, 215, 155);
  justify-content: space-evenly;
  padding-left: 0rem;
  height: 100%;
}

.team ul {
  padding-top:2rem;
  padding-bottom:2rem;
  display: flex;
  align-self: center;
  flex-direction: row;
  gap: 4rem;
  height: 100%;
  width: 80%;
  flex-wrap: wrap;
  justify-content: center;
}
.team p{
  width: 100%;
  color:rgb(255, 215, 155);
  font-size: 0.8rem;
}
.container {  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 0.1fr;
  gap: 0px 0px;
  height: 100vh;
  width: 100%;
  box-shadow: 0 0 15px rgb(51, 50, 50);
  padding-top:1rem;
  border-top: 2px solid rgb(255, 215, 155);;
  grid-auto-flow: row;
  grid-template-areas:
  "left_container"
  "right_container"
  "copyright";
}

.left_container { 
grid-area: left_container; 
background-color: rgb(0, 0, 0);
text-align: center;
border-bottom: 2px solid rgb(255, 215, 155) ;
padding-bottom:1rem;
padding-top: 1rem;
}
.left_container li{ 
padding-top: 0.3rem;
}
.container p {
  color:rgb(255, 215, 155);
  font-size:1rem;
}
.container h2{
  color:rgb(255, 215, 155);
  font-size: 1.1rem;
}

.left_container img{ 
  width: 3rem;
  height:100%; }

.right_container {
   grid-area: right_container;
   background-color: rgb(0, 0, 0);
   text-align: center; 
   border-bottom: 2px solid rgb(255, 215, 155);
   padding-bottom:1rem;
   padding-right:0rem;
   padding-top: 1rem;}

.copyright { 
  padding-top: 1rem;
  grid-area: copyright;
  
 }
 .copyright p{
 color:rgb(255, 215, 155);
 text-align: center;
 padding-bottom:1rem;
 }
    }