@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
}

body {
  background: rgb(0, 0, 0);
}

h1,h4,p,a{
  color: black;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

